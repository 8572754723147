import * as ReactRedux from 'react-redux'
import React from 'react'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import * as Session from './session'
import { QueryDrawer } from './query-drawer'

const LoginBox = loadable(() => import('./login-box'))

export function ManualLoginDrawer() {
  const i18n = I18n.useI18n()
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )

  if (authenticated) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="manual-login"
      title={i18n.translate('login-page.title')}
    >
      <Common.Box pb={6} color="g-text">
        <LoginBox />
      </Common.Box>
    </QueryDrawer>
  )
}

// for @loadable/components
export default ManualLoginDrawer
